import {Fragment} from "preact";
import style from './style.scss';
import {useEffect, useState} from "preact/hooks";
import {useMasonryData} from "./masonryData";
import LazyImage from "../lazy-image";
import VideoCell from "../video-cell";


const MediaCell = (props) => {
        const media = props.media;

        const [isVideoVisible, setVideoVisibility] = useState(false);

        const onHover = (evt) => {
            evt.stopPropagation();
            props.onCellEnter();
        };

        const onLeave = (evt) => {
            evt.stopPropagation();
            props.onCellLeave();
        };

        let overlayStyle = {
            position: 'absolute',
            borderRadius: '10px',
            backgroundColor: 'black',
            opacity: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            transition: '0.3s ease-out all',
            userSelect: 'none'
        }

        if (props.isActive) {
            overlayStyle = {...overlayStyle, ...{opacity: 0.35}}
        }

        useEffect(() => {
            if (props.activeMedia && props.media.id === props.activeMedia.id) {
                setVideoVisibility(true);
            }
            return () => setVideoVisibility(false);
        }, [props.activeMedia])

        return (<div class={style['media-wrapper']}
                     onMouseEnter={onHover}
                     onMouseLeave={onLeave}
                     onClick={() => {
                         props.handleClick(media);
                         setVideoVisibility(false);
                     }}>
            {(!media.video || (media.video && !media.video.always_play_thumbnail)) &&
            <LazyImage src={media.image.thumbnail} borderRadius={'10px'} />}
            {(media.video && (media.video.always_play_thumbnail || isVideoVisible)) &&
            <div className={style['video-wrapper']}>
                <VideoCell src={media.video.thumbnail_video} poster={media.image.thumbnail} borderRadius={'10px'} />
            </div>
            }
            <div style={overlayStyle} />
        </div>)
    }
;

const MasonryGrid = (props) => {

        const data = useMasonryData(props.breakpoints);

        const [activeMedia, setActiveMedia] = useState(undefined);

        const onCellEnter = media => setActiveMedia(media);

        const onCellLeave = () => setActiveMedia(undefined);

        const positionStyle = it => {
            return {
                position: "absolute",
                height: it.height,
                width: it.width,
                top: it.top,
                left: it.left,
            }
        }

        useEffect(() => {
            document.body.style.overflow = props.disableScroll ? 'hidden' : 'inherit';
        }, [props.disableScroll])

        return <div class={style.parent}>
            <div id='container' class={style['container']}>
                {data.map(it =>
                    <div style={positionStyle(it)}>
                        {it.media.id !== "blank" ?
                            <MediaCell
                                key={it.id}
                                media={it.media}
                                handleClick={() => props.handleClick(it.media)}
                                onCellEnter={() => onCellEnter(it.media)}
                                onCellLeave={() => onCellLeave()}
                                activeMedia={activeMedia}
                                isActive={activeMedia && activeMedia.groupId === it.media.groupId && it.media.id !== activeMedia.id}
                            /> : <Fragment />}
                    </div>
                )}
            </div>
        </div>
    }
;

export default MasonryGrid;
