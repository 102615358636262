import style from './style.scss';
import Back from "../back";

const Header = (props) => {
    return (
        <div class={style["header"]}>
            <Back/>
        </div>
    )
}

export default Header;
