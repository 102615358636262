// eslint-disable-next-line no-unused-vars
import React from "preact";
import style from './style.scss';
import {aboutMeImg} from "../../service/imgService";
import {literacy as data} from "../../dataSource/aboutMe";
import {Details, Download} from "../about-me-common-components";


const LiteracyMobile = () => {
    return <div className={style.parent}>
        <div className={style.container}>
            <div className={style['top']}>
                <div className={style['main-image-wrapper']}>
                    <img src={aboutMeImg("literacy_shadow")} alt="yash-shadow" className={style['main-image-shadow']} />
                    <img src={aboutMeImg("literacy")} alt="yash" className={style['main-image']} />
                </div>
            </div>
            <div className={style.middle}>
                <div className={style['middle-wrapper']}>
                    <div className={style.header}>literacy</div>
                </div>
            </div>
            <div className={style.bottom}>
                <Details data={data}>
                    <Download />
                </Details>
            </div>
        </div>
    </div>
}

export default LiteracyMobile;
