import detailsStyle from './details.scss';
import downloadStyle from './download.scss';
import headerStyle from './header.scss';
import {cvUrl} from "../../dataSource/home";

export const Header = (props) => <div className={headerStyle.header}>{props.header}</div>;

export const Details = (props) => {
    const style = detailsStyle;
    return <div className={detailsStyle.details}>
        <div className={style['title-container']}>
            {props.data.map(it => <div className={style['title-wrapper']}>
                <div className={style['title']}>{it.text1}</div>
                <div className={style['symbol']}>&#8250;</div>
            </div>)}
        </div>
        <div className={style.desc}>
            {props.data.map(it => (
                it.text2
                    ? <div className={style.cell}>{it.text2}{it.text3 && <><div className={style['separator-line']}>|</div> <div className={style.info}>{it.text3}</div></>}</div>
                    : <div className={style.cell}>{props.children}</div>
                )
            )}
        </div>
    </div>
}

export const Download = () => {
    const downloadCv = () => {
        window.open(cvUrl, '_blank');
    }

    return <div className={downloadStyle['download-cv']} onClick={() => downloadCv()}>download CV</div>
}

