import { createRef } from "preact";
import { useEffect, useState } from "preact/hooks";
import Header from '../header';
import LazyImage from "../lazy-image";
import Video from "../video";
import style from './style.scss';

const getHeight = (containerWidth, ratio) => {
    let width = containerWidth;
    const [ratioWidth, ratioHeight] = ratio.split(":").map(it => parseInt(it));
    let height = containerWidth / (ratioWidth / ratioHeight);
    const availabeHeight = document.body.clientHeight - 80;
    if (availabeHeight < height) {
        const scale = availabeHeight / height;
        height = height * scale;
        width = height * ratioWidth / ratioHeight;
    }
    
    return height;
};

const getWidth = (containerWidth, ratio) => {
    let width = containerWidth;
    const [ratioWidth, ratioHeight] = ratio.split(":").map(it => parseInt(it));
    let height = containerWidth / (ratioWidth / ratioHeight);
    const availabeHeight = document.body.clientHeight - 80;
    if (availabeHeight < height) {
        const scale = availabeHeight / height;
        height = height * scale;
        width = height * ratioWidth / ratioHeight;   
    }
    
    return width;
};
const VerticalPreview = (props) => {
    const scrollContainerRef = createRef();
    const [parentWidth, setParenWidth] = useState(0);

    const group = props.data.group;

    useEffect(() => {
        const containerWidth = scrollContainerRef.current.clientWidth;
        setParenWidth(containerWidth);
        if (group.length === 1) {
            scrollContainerRef.current.style.justifyContent = "center";
            scrollContainerRef.current.style.marginTop = "0";
        }
    }, [])

    return <div className={style['preview-parent']} onClick={() => props.handleBackClick()}>
        <div className={style['preview-container']}>
            <Header/>
            <div className={style['scroll-container']} ref={scrollContainerRef} onClick={evt => evt.stopPropagation()}>
                {group.map((it, index) => {
                    return (
                        <div className={style['media-wrapper']} key={index}>
                            {it.video
                                ? <Video src={it.video.src} height={getHeight(parentWidth, it.ratio)} width={getWidth(parentWidth, it.ratio)} />
                                : <LazyImage src={it.image.src} />}
                        </div>
                    )
                })}
            </div>
        </div>

    </div>
}

export default VerticalPreview;
