import {advertsLogo, advertsOriginal, advertsThumbnail} from "../service/imgService";

export const adverts = [
    {
        image_name: "kwality",
        ratio: "1000:562",
        videoId: "V4dPeIPiJbM",
        groupId: "kwality-1",
        id: 1
    },
    {
        image_name: "stayfree1",
        ratio: "1000:562",
        videoId: "PcNKTwgZEEk",
        groupId: "stayfree-1",
        id: 2
    },
    {
        image_name: "stayfree2",
        ratio: "1000:562",
        videoId: "khlgJP1sICE",
        groupId: "stayfree-2",
        id: 3
    },
    {
        image_name: "facebook",
        ratio: "700:394",
        videoId: "HmMeUiZp-bE",
        groupId: "facebook",
        id: 4
    },
    {
        image_name: "polo",
        ratio: "16:9",
        videoId: "C0U1Gf6tej4",
        groupId: "polo",
        id: 5
    },
    {
        image_name: "vw1",
        ratio: "16:9",
        groupId: "volkswagen",
        id: 6
    },
    {
        image_name: "vw2",
        ratio: "16:9",
        groupId: "volkswagen",
        id: 7
    },
    {
        image_name: "vw3",
        ratio: "16:9",
        groupId: "volkswagen",
        id: 8
    },
    {
        image_name: "md1",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 9
    },
    {
        image_name: "md2",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 10
    },
    {
        image_name: "md3",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 11
    },
    {
        image_name: "md4",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 12
    },
    {
        image_name: "cor1",
        ratio: "16:9",
        videoId: "n47l41MalLk",
        groupId: "cornetto",
        id: 13
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "cor2",
        ratio: "16:9",
        groupId: "cornetto",
        id: 14
    },
    {
        image_name: "cor3",
        ratio: "707:1000",
        groupId: "cornetto",
        id: 15
    },
    {
        image_name: "cor4",
        ratio: "707:1000",
        groupId: "cornetto",
        id: 16
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "cor5",
        ratio: "16:9",
        videoId: "GQy4T2PLPm4",
        groupId: "cornetto",
        id: 17
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "global1",
        ratio: "16:9",
        videoId: "U9ODOh0sNW4",
        groupId: "walls-global",
        id: 18
    },
    {
        image_name: "result",
        ratio: "16:9",
        videoId: "9DfxqhSHhC4",
        groupId: "result",
        id: 19
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "trix1",
        ratio: "16:9",
        videoId: "AaRhMH9-Vj8",
        groupId: "trix",
        id: 20
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "trix2",
        ratio: "707:1000",
        groupId: "trix",
        id: 21
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "trix3",
        ratio: "707:1000",
        groupId: "trix",
        id: 22
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "bbc1",
        ratio: "16:9",
        videoId: "Cc7ikWnHsU8",
        groupId: "bbc",
        id: 23
    },
    {
        image_name: "bbc2",
        ratio: "627:1000",
        groupId: "bbc",
        id: 24
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep1",
        ratio: "16:9",
        groupId: "jeep",
        id: 25
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep4",
        ratio: "16:9",
        groupId: "jeep",
        id: 26
    },
    {
        image_name: "jeep2",
        ratio: "16:9",
        groupId: "jeep",
        id: 27
    },
    {
        image_name: "jeep3",
        ratio: "16:9",
        groupId: "jeep",
        id: 28
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep6",
        ratio: "16:9",
        groupId: "jeep",
        id: 29
    },
    {
        image_name: "jeep5",
        ratio: "16:9",
        groupId: "jeep",
        id: 30
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep7",
        ratio: "16:9",
        groupId: "jeep",
        id: 31
    },
    {
        image_name: "jeep8",
        ratio: "651:1000",
        groupId: "jeep",
        id: 32
    },
    {
        image_name: "jeep9",
        ratio: "16:9",
        groupId: "jeep",
        id: 33
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep10",
        ratio: "16:9",
        groupId: "jeep",
        id: 34
    },
    {
        image_name: "vw-1",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 35
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "vw-3",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 36
    },
    {
        image_name: "vw-2",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 37
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "vw-4",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 38
    },
    {
        image_name: "vw-5",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 39
    },
    {
        image_name: "vw-6",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 40
    },
    {
        image_name: "vw-11",
        ratio: "2:1",
        extension: "gif",
        groupId: "volkswagen-new",
        id: 41
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "vw-7",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 42
    },
    {
        image_name: "vw-8",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 43
    },
    {
        image_name: "vw-9",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 44
    },
    {
        image_name: "vw-10",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 45
    },
    {
        image_name: "covid1",
        ratio: "323:457",
        videoId: "Lx23epi7WgA",
        groupId: "covid",
        id: 46
    },
    {
        image_name: "mc3",
        ratio: "707:1000",
        groupId: "mac-d",
        id: 47
    },
    {
        image_name: "mc1",
        ratio: "707:1000",
        groupId: "mac-d",
        id: 48
    },
    {
        image_name: "mc2",
        ratio: "707:1000",
        groupId: "mac-d",
        id: 49
    },
].map(mapTo);

export const adverts_mobile = [
    {
        image_name: "kwality",
        ratio: "1000:562",
        videoId: "V4dPeIPiJbM",
        groupId: "kwality-1",
        id: 1
    },
    {
        image_name: "stayfree1",
        ratio: "1000:562",
        videoId: "PcNKTwgZEEk",
        groupId: "stayfree-1",
        id: 2
    },
    {
        image_name: "stayfree2",
        ratio: "1000:562",
        videoId: "khlgJP1sICE",
        groupId: "stayfree-2",
        id: 3
    },
    {
        image_name: "facebook",
        ratio: "700:394",
        videoId: "HmMeUiZp-bE",
        groupId: "facebook",
        id: 4
    },
    {
        image_name: "polo",
        ratio: "16:9",
        videoId: "C0U1Gf6tej4",
        groupId: "polo",
        id: 5
    },
    {
        image_name: "vw1",
        ratio: "16:9",
        groupId: "volkswagen",
        id: 6
    },
    {
        image_name: "vw2",
        ratio: "16:9",
        groupId: "volkswagen",
        id: 7
    },
    {
        image_name: "vw3",
        ratio: "16:9",
        groupId: "volkswagen",
        id: 8
    },
    {
        image_name: "md1",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 9
    },
    {
        image_name: "md2",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 10
    },
    {
        image_name: "md3",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 11
    },
    {
        image_name: "md4",
        ratio: "707:1000",
        groupId: "mcdonalds-1",
        id: 12
    },
    {
        image_name: "cor1",
        ratio: "16:9",
        videoId: "n47l41MalLk",
        groupId: "cornetto",
        id: 13
    },
    {
        image_name: "cor2",
        ratio: "16:9",
        groupId: "cornetto",
        id: 14
    },
    {
        image_name: "cor3",
        ratio: "707:1000",
        groupId: "cornetto",
        id: 15
    },
    {
        image_name: "cor4",
        ratio: "707:1000",
        groupId: "cornetto",
        id: 16
    },
    {
        image_name: "cor5",
        ratio: "16:9",
        videoId: "GQy4T2PLPm4",
        groupId: "cornetto",
        id: 17
    },
    {
        image_name: "global1",
        ratio: "16:9",
        videoId: "U9ODOh0sNW4",
        groupId: "walls-global",
        id: 18
    },
    {
        image_name: "result",
        ratio: "16:9",
        videoId: "9DfxqhSHhC4",
        groupId: "result",
        id: 19
    },
    {
        image_name: "trix1",
        ratio: "16:9",
        videoId: "AaRhMH9-Vj8",
        groupId: "trix",
        id: 20
    },
    {
        image_name: "trix2",
        ratio: "707:1000",
        groupId: "trix",
        id: 21
    },
    {
        image_name: "trix3",
        ratio: "707:1000",
        groupId: "trix",
        id: 22
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "bbc1",
        ratio: "16:9",
        videoId: "Cc7ikWnHsU8",
        groupId: "bbc",
        id: 23
    },
    {
        image_name: "bbc2",
        ratio: "627:1000",
        groupId: "bbc",
        id: 24
    },
    {
        image_name: "jeep1",
        ratio: "16:9",
        groupId: "jeep",
        id: 25
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep4",
        ratio: "16:9",
        groupId: "jeep",
        id: 26
    },
    {
        image_name: "jeep2",
        ratio: "16:9",
        groupId: "jeep",
        id: 27
    },
    {
        image_name: "jeep3",
        ratio: "16:9",
        groupId: "jeep",
        id: 28
    },
    {
        image_name: "jeep6",
        ratio: "16:9",
        groupId: "jeep",
        id: 29
    },
    {
        image_name: "jeep5",
        ratio: "16:9",
        groupId: "jeep",
        id: 30
    },
    {
        image_name: "jeep7",
        ratio: "16:9",
        groupId: "jeep",
        id: 31
    },
    {
        image_name: "jeep8",
        ratio: "651:1000",
        groupId: "jeep",
        id: 32
    },
    {
        image_name: "jeep9",
        ratio: "16:9",
        groupId: "jeep",
        id: 33
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        image_name: "jeep10",
        ratio: "16:9",
        groupId: "jeep",
        id: 34
    },
    {
        image_name: "vw-1",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 35
    },
    {
        image_name: "vw-3",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 36
    },
    {
        image_name: "vw-2",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 37
    },
    {
        image_name: "vw-4",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 38
    },
    {
        image_name: "vw-5",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 39
    },
    {
        image_name: "vw-6",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 40
    },
    {
        image_name: "vw-7",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 41
    },
    {
        image_name: "vw-11",
        ratio: "2:1",
        extension: "gif",
        groupId: "volkswagen-new",
        id: 42
    },
    {
        image_name: "vw-8",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 43
    },
    {
        image_name: "vw-9",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 44
    },
    {
        image_name: "vw-10",
        ratio: "16:9",
        groupId: "volkswagen-new",
        id: 45
    },
    {
        image_name: "covid1",
        ratio: "323:457",
        videoId: "Lx23epi7WgA",
        groupId: "covid",
        id: 46
    },
    {
        image_name: "mc3",
        ratio: "707:1000",
        groupId: "mac-d",
        id: 47
    },
    {
        image_name: "mc1",
        ratio: "707:1000",
        groupId: "mac-d",
        id: 48
    },
    {
        image_name: "mc2",
        ratio: "707:1000",
        groupId: "mac-d",
        id: 49
    },
].map(mapTo);

function mapTo(media) {
    media['image'] = {
        thumbnail: advertsThumbnail(media.image_name, media.extension),
        src: advertsOriginal(media.image_name, media.extension)
    }
    if (media.videoId) {
        media['video'] = {
            id: media.videoId,
            src: `https://www.youtube.com/embed/${media.videoId}`,
            thumbnail_video: advertsThumbnail(media.image_name, "mp4")
        };
    }
    delete media['videoId'];
    delete media['image_name'];
    return media;
}

export const advertsDescription = [
    {
        id: "kwality-1",
        logo: {src: "KW", top: "-60px", width: "70px"},
        layout: {paddingTop: "90px"},
        description: "All “Art directors”\nwish to have a film in their book that\nis entirely theirs. This is that\n‘dream-come-true’ TVC for me.\nFun fact: The ‘first draft’ of\nmy script is what was filmed.\nNo edits. No changes.\nYes, even I thought I was dreaming.\nAnd to add to the whole\ndreamy vibe, this even won\na prestigious Kyoorius Elephant.",
        closeText: "And… Action!",
    },
    {
        id: "stayfree-1",
        logo: {src: "SF", top: "-60px", width: "90px"},
        layout: {paddingTop: "100px"},
        description: "A girl feels incredibly uncomfortable,\nlost and insecure when\nshe gets her first period. (Damn.)\nAnd wait, now imagine a staggering\n23 million girls in India getting their\nfirst period when they are confined in a\nroom with the entire country in a state\nof lockdown. (Double damn.)\nAnd that is the ‘Insight’ of this film.\nResults?\n- Silver for Video and a Bronze for\nSocial under the Digital Strategy\ncategory at Spikes Asia.\nBut even better? Breaking a cultural\ntaboo in the best way possible.",
        closeText: "Triple freakin’ damn!"
    },
    {
        id: "stayfree-2",
        logo: {src: "SKC", top: "-40px", width: "110px"},
        layout: {paddingTop: "80px"},
        description: "The Stayfree ‘cloth’ film spoke\nabout the hindrance and\nhumiliation that young girls have\nto face was a poignant success\nin breaking taboos.\nSo we decided to take it further and\ncollaborate with UNICEF to reach\nout to girls to talk about what they\nare most hesitant to - their periods.\nGuess what it won?\n- A Majestic Silver Effie!\nCome on, this had to happen!",
        closeText: "You gotta love a happy beginning too"
    },
    {
        id: "facebook",
        logo: null,
        layout: {paddingTop: "50px"},
        description: "During the worldwide\ncoronavirus lockdown,\nthere was another, just as deadly\nepidemic on the rise.\nAnd that illness was Depression.\nAlong with Facebook,\nthe social media giant,\nwe wanted to spread awareness\nabout the same and get people\nto reach out and support\none another. Because this\npandemic reinforced human care\nand connection in us,\nthat must be given freely.",
        closeText: "Seems Epic-demic!"
    },
    {
        id: "walls-global",
        logo: null,
        layout: {paddingTop: "50px"},
        description: "During this pandemic\nthere were some heartwarming\nstories of people who took it upon\nthemselves to spread happiness.\nAnd so for Wall’s\nwe took ‘Happier Together’ stories\nfrom 5 countries - Turkey, Mexico,\nGermany, India and Indonesia;\nand showed them to the world.\nBecause one can never get enough\nof happiness.",
        closeText: "Insert Pharrell William’s ‘Happy’"
    },
    {
        id: "polo",
        logo: {src: "GCFI", top: "-40px", width: "90px"},
        layout: {paddingTop: "60px"},
        description: "An Indian Cricket Superstar\ncheers for a German Football team\nduring the FIFA World Cup, 2016.\nNow it’s 2019,\nwe have the Indian Cricket Team\nplaying in the Cricket World Cup.\nWould Germans return the cheer?\nWhy would they, right?\nThere’s a reason this won a nomination\nat DDB’s Global Lemon Awards. ",
        closeText: "Patriotic vibes happening!"
    }
].map(it => {
    if (it.logo) {
        it.logo.src = advertsLogo(it.logo.src);
    }
    return it;
})
