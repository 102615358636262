import {artsOriginal, artsThumbnail} from "../service/imgService";

export const arts_mobile = [
    {
        id: 1,
        image_name: "bumble1",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 2,
        image_name: "bumble2",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 3,
        image_name: "bumble3",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 4,
        image_name: "bumble4",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 5,
        image_name: "bumble5",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 6,
        image_name: "bumble6",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 7,
        image_name: "bumble7",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 8,
        image_name: "bumble8",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 9,
        image_name: "bumble9",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 10,
        image_name: "park1",
        groupId: 2,
        ratio: "16:9",
        videoId: "mRM_W7_eN6I"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 11,
        image_name: "park4",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 12,
        image_name: "park5",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 13,
        image_name: "park6",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 14,
        image_name: "park7",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 15,
        image_name: "park3",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 16,
        image_name: "park9",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 17,
        image_name: "park10",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 20,
        image_name: "park8",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 18,
        image_name: "nba1",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 19,
        image_name: "nba2",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 23,
        image_name: "nba3",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 24,
        image_name: "nba4",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 25,
        image_name: "nba5",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 21,
        image_name: "nba6",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 22,
        image_name: "nba7",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 28,
        image_name: "nba8",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 29,
        image_name: "nba9",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 30,
        image_name: "nba10",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 26,
        image_name: "nba11",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 27,
        image_name: "nba12",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 33,
        image_name: "nba13",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 34,
        image_name: "gc3",
        groupId: 5,
        ratio: "11:16"
    },
    {
        id: 35,
        image_name: "axn7",
        groupId: 6,
        ratio: "11:16"
    },
    {
        id: 31,
        image_name: "tc1",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 32,
        image_name: "tc2",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 38,
        image_name: "tc3",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 39,
        image_name: "tc4",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 40,
        image_name: "gc2",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 45,
        image_name: "gc1",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 36,
        image_name: "gc5",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 37,
        image_name: "gc4",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 43,
        image_name: "axn6",
        groupId: 9,
        ratio: "16:9"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 44,
        image_name: "axn5",
        groupId: 9,
        ratio: "11:16"
    },
    {
        id: 41,
        image_name: "axn1",
        groupId: 9,
        ratio: "16:11"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 42,
        image_name: "axn2",
        groupId: 9,
        ratio: "16:9"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 48,
        image_name: "axn3",
        groupId: 9,
        ratio: "16:9"
    },
    {
        id: 49,
        image_name: "axn4",
        groupId: 9,
        ratio: "16:9"
    },
    {
        id: 50,
        image_name: "mcd1",
        groupId: 11,
        ratio: "16:9"
    },
    {
        id: 55,
        image_name: "mcd2",
        groupId: 11,
        ratio: "16:9"
    },
    {
        id: 57,
        image_name: "mcd3",
        groupId: 11,
        ratio: "11:16"
    },
    {
        id: 46,
        image_name: "goomo1",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 47,
        image_name: "goomo2",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 53,
        image_name: "goomo3",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 54,
        image_name: "goomo4",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 51,
        image_name: "goomo5",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 52,
        image_name: "goomo6",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 56,
        image_name: "goomo7",
        groupId: 10,
        ratio: "11:16"
    },

].map(mapTo);

export const arts = [
    {
        id: 1,
        image_name: "bumble1",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 2,
        image_name: "bumble2",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 3,
        image_name: "bumble3",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 4,
        image_name: "bumble4",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 5,
        image_name: "bumble5",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 6,
        image_name: "bumble6",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 7,
        image_name: "bumble7",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 8,
        image_name: "bumble8",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 9,
        image_name: "bumble9",
        groupId: 1,
        ratio: "11:16"
    },
    {
        id: 10,
        image_name: "park1",
        groupId: 2,
        ratio: "16:9",
        videoId: "mRM_W7_eN6I"
    },
    {
        id: 11,
        image_name: "park4",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 12,
        image_name: "park5",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 13,
        image_name: "park6",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 14,
        image_name: "park7",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 15,
        image_name: "park3",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 16,
        image_name: "park9",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 17,
        image_name: "park10",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 20,
        image_name: "park8",
        groupId: 2,
        ratio: "11:16"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 18,
        image_name: "nba1",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 19,
        image_name: "nba2",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 23,
        image_name: "nba3",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 24,
        image_name: "nba4",
        groupId: 3,
        ratio: "16:11"
    },
    {
        id: 25,
        image_name: "nba5",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 21,
        image_name: "nba6",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 22,
        image_name: "nba7",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 28,
        image_name: "nba8",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 29,
        image_name: "nba9",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 30,
        image_name: "nba10",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 26,
        image_name: "nba11",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 27,
        image_name: "nba12",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 33,
        image_name: "nba13",
        groupId: 3,
        ratio: "11:16"
    },
    {
        id: 34,
        image_name: "gc3",
        groupId: 5,
        ratio: "11:16"
    },
    {
        id: 35,
        image_name: "axn7",
        groupId: 6,
        ratio: "11:16"
    },
    {
        id: 31,
        image_name: "tc1",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 32,
        image_name: "tc2",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 38,
        image_name: "tc3",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 39,
        image_name: "tc4",
        groupId: 4,
        ratio: "11:16"
    },
    {
        id: 40,
        image_name: "axn6",
        groupId: 8,
        ratio: "16:9"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 45,
        image_name: "axn5",
        groupId: 8,
        ratio: "11:16"
    },
    {
        id: 36,
        image_name: "gc1",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 37,
        image_name: "gc2",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 43,
        image_name: "gc4",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 44,
        image_name: "gc5",
        groupId: 7,
        ratio: "11:16"
    },
    {
        id: 50,
        image_name: "mcd1",
        groupId: 11,
        ratio: "16:9"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 55,
        image_name: "mcd2",
        groupId: 11,
        ratio: "16:9"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 57,
        image_name: "mcd3",
        groupId: 11,
        ratio: "11:16"
    },
    {
        id: 41,
        image_name: "axn1",
        groupId: 8,
        ratio: "16:9"
    },
    {
        id: 42,
        image_name: "axn2",
        groupId: 8,
        ratio: "16:9"
    },
    {
        id: 48,
        image_name: "axn3",
        groupId: 8,
        ratio: "16:9"
    },
    {
        id: 49,
        image_name: "axn4",
        groupId: 8,
        ratio: "16:9"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 46,
        image_name: "goomo1",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 47,
        image_name: "goomo2",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 53,
        image_name: "goomo3",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 54,
        image_name: "goomo4",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 'blank',
        image_name: '',
        groupId: '',
        ratio: ''
    },
    {
        id: 51,
        image_name: "goomo5",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 52,
        image_name: "goomo6",
        groupId: 10,
        ratio: "11:16"
    },
    {
        id: 56,
        image_name: "goomo7",
        groupId: 10,
        ratio: "11:16"
    },

].map(mapTo);

function mapTo(media) {
    media['image'] = {
        thumbnail: artsThumbnail(media.image_name, media.extension),
        src: artsOriginal(media.image_name, media.extension)
    }
    if (media.videoId) {
        media['video'] = {
            id: media.videoId,
            src: `https://www.youtube.com/embed/${media.videoId}`,
            thumbnail_video: artsThumbnail(media.image_name, "mp4"),
            always_play_thumbnail: true
        };
    }
    delete media['videoId'];
    delete media['image_name'];
    return media;
}

export const artsDescription = [
    {
        id: 2,
        logo: null,
        layout: {paddingTop: "50px"},
        description: "We made BTS videos a thing,\nbefore they were a thing. For real.\nWe truly ‘hand made’ prints for\nPark Avenue in a very colourful,\nold school manner and sidelined\nShutterstock and Getty images.\nOn paper. By hand.\nThe concept?\nThe idea that wearing Park Avenue\nat the start of the day is like applying\nWar Paint before you start your\ndaily hustle. As every single day is\na War and one should #WearTheFight.\nI suggest you not to skip the BTS video.",
        closeText: "And I take your suggestion to get rid of these Pop-ups. Win-Win"
    }
]
