import { awardsLogo, awardsOriginal, awardsThumbnail } from "../service/imgService";

export const awardsThumbnails = [
    {id: 1, image: "1jugnooH", color: "#f2fc75"},
    {id: 2, image: "2ThirdH", color: "#e9c44a"},
    {id: 3, image: "3paraH", color: "#fae04c"},
    {id: 4, image: "4dislabelH", color: "#71f8fa"},
    {id: 5, image: "5salaamH", color: "#D5D361"},
    {id: 6, image: "6signalH", color: "#52a076"},
    {id: 7, image: "7loveH", color: "#CBa43b"},
    {id: 8, image: "8mercyH", color: "#9f2d33"},
    {id: 9, image: "9hateH", color: "#771d1c"},
    {id: 10, image: "10jeepH", color: "#d3693b"}
].map(it => {
    it.thumbnail = awardsThumbnail(it.image);
    return it;
})

export const awardsDescription = [
    {
        id: 1,
        logo: {src: "JUGNOO", top: "-35px", width: "50px"},
        layout: {paddingTop: "120px"},
        description: 'What’s even better than\na D&AD Wood Pencil?\nA Yellow one. Sure. But how about\ngetting a Brand Hero Award along\nwith it for starters. And add to that,\nan opportunity to inspire young minds\nby conducting global sessions for\nMiami Ad School in London,\nand we have a trifecta of epicness.\nAlso, we actually made Pearson cry\nthe happiest of tears with this idea\nand that for me goes right up there\nwith the trifecta of epicness.',
        closeText: "Must. Not. Get. Emo."
    },
    {
        id: 2,
        logo: {src: "THIRDFONT", top: "-50%", width: "90px"},
        layout: {paddingTop: "70px"},
        description: "Another D&AD entry\nthat won nothing but hearts and\nmade mine simply burst with pride.\nMonotype wanted to create a typeface\nfor a social cause. And we decided\nto bring to the spotlight,\nthe most neglected global community\n– TRANSGENDERS.\nOur aim was to showcase how a ‘font’\ncan take on the characteristics and\nboldness of an entire community\nand in doing so help bring imposing\nchange in their lives.",
        closeText: "It’s definitely not ‘Comic Sans’"
    },
    {
        id: 3,
        logo: {src: "PARA", top: "-70px", width: "90px"},
        layout: {paddingTop: "60px"},
        description: "Having an idea\nto turn a perceived weakness into\npath defining strength, is exactly\nhow one wins a Silver Young Glory.\nBut more importantly than that,\nit is exactly how one brings\nmuch needed attention\nto the Paralympics and\nthe superhero-esque Paralympians\nby bringing them front and centre.",
        closeText: "Superheroes, exist!"
    },
    {
        id: 4,
        logo: null,
        layout: {paddingTop: "50px"},
        description: "There’s a lot rightly said about\nhow labelling people in general\nis wrong. But what’s even worse is\nlabelling people incorrectly.\nAnd that’s what DIS-LABELLED\naimed to change about the\nParalympians and any person who is\n‘differently abled’.\nHow about you just watch this.",
        closeText: "...and I would stop talking.",
    },
    {
        id: 5,
        logo: {src: "SALAAM", top: "-70px", width: "110px"},
        layout: {paddingTop: "70px"},
        description: "From Gold to Silver\nto Bronze and Shortlists\nat Spikes Asia\nand Cannes Lions,\nthis simple idea shows\nhow to provoke change\nin people's lives.",
        closeText: "Kept it short. You’re welcome!"
    },
    {
        id: 6,
        logo: {src: "LIFESIGNAL", top: "-93px", width: "110px"},
        layout: {paddingTop: "90px"},
        description: "One of those ideas where\nyou’re walking down the street,\nyou see an actual problem\nand try to think of a solution.\nThen, you cut an AV.\nPitch it at work.\nGet appreciated and nominated\nfor the Global Product Committee,\nA.K.A. Leo Burnett’s Fight Club.",
        closeText: "And also it could save lives."
    },
    {
        id: 7,
        logo: {src: "LOVEFILTER", top: "-27px", width: "160px"},
        layout: {paddingTop: "50px"},
        description: "Growing older is a fear that\nmost of us have at some level.\nAnd being forgotten\nis yet another deep fear\n.So what happens when you\nhighlight those fears by hacking\na pop culture trend and\nactually bring some awareness\nto the plight of the elderly?\nWell you ace the brief given by\nCampaign India and make it\nin their ‘TOP 5’ list, nation-wide!.",
        closeText: "Some unfiltered bragging."
    },
    {
        id: 8,
        logo: {src: "MERCY", top: "-50%", width: "70px"},
        layout: {paddingTop: "70px"},
        description: "The FIRST award entry\nof my life ended up winning at\nSECOND place.\nI was elated to have my name\nbeing mentioned on-stage at\nSingapore’s Young Spikes\nawards’ ceremony. Even though\nthey crushed it in their accent.",
        closeText: " Indian names are funny!"
    },
    {
        id: 9,
        logo: {src: "HATE", top: "-70px", width: "90px"},
        layout: {paddingTop: "60px"},
        description: "Recruiters for radical hate group\n have become experts at\nbrainwashing and trapping\nthe youth on social media and\nthen getting them to be part of\ndisastrous operations.\nSo how do we battle this?\nBy a radically simple plug-in that\ncan help the youth sever ties with\nthese recruiters and save them\nfrom getting ensnared in something\nthey’re sure to regret later.",
        closeText: "Goose-bumpy. Innit?"
    },
].map(it => {
    if (it.logo) {
        it.logo.src = awardsLogo(it.logo.src);
    }
    return it;
})

export const awardsDetails = [
    {
        id: "1-1",
        image_name: "",
        videoId: "NRxbrwi4WTE",
        ratio: "16:9",
        groupId: 1
    },
    {
        id: "2-1",
        image_name: "1thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-2",
        image_name: "2thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-3",
        image_name: "3thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-4",
        image_name: "4thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-5",
        image_name: "5thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-6",
        image_name: "6thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-7",
        image_name: "7thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-8",
        image_name: "8thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-9",
        image_name: "9thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "2-10",
        image_name: "10thirdInside",
        ratio: "16:9",
        groupId: 2
    },
    {
        id: "3-1",
        image_name: "1paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "3-2",
        image_name: "2paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "3-3",
        image_name: "3paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "3-4",
        image_name: "4paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "3-5",
        image_name: "5paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "3-6",
        image_name: "6paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "3-7",
        image_name: "7paraInside",
        ratio: "16:9",
        groupId: 3
    },
    {
        id: "4-1",
        image_name: "",
        videoId: "IhgTAt8Z3aY",
        ratio: "16:9",
        groupId: 4
    },
    {
        id: "5-1",
        image_name: "",
        videoId: "YiGkVg9oUpA",
        ratio: "16:9",
        groupId: 5
    },
    {
        id: "6-1",
        image_name: "",
        videoId: "HkA7oioz4b8",
        ratio: "16:9",
        groupId: 6
    },
    {
        id: "7-1",
        image_name: "1loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-2",
        image_name: "2loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-3",
        image_name: "3loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-4",
        image_name: "4loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-5",
        image_name: "5loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-6",
        image_name: "6loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-7",
        image_name: "7loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-8",
        image_name: "8loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-9",
        image_name: "9loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-10",
        image_name: "10loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "7-11",
        image_name: "11loveinside",
        ratio: "16:9",
        groupId: 7
    },
    {
        id: "8-1",
        image_name: "1mercyInside",
        ratio: "16:9",
        groupId: 8
    },
    {
        id: "8-2",
        image_name: "2mercyInside",
        ratio: "16:9",
        groupId: 8
    },
    {
        id: "9-1",
        image_name: "1hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-2",
        image_name: "2hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-3",
        image_name: "3hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-4",
        image_name: "4hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-5",
        image_name: "5hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-6",
        image_name: "6hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-7",
        image_name: "7hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "9-8",
        image_name: "8hateinside",
        ratio: "16:9",
        groupId: 9
    },
    {
        id: "10-1",
        image_name: "1jeepInside",
        ratio: "16:9",
        groupId: 10
    }
].map(media => {
    media['image'] = {
        thumbnail: awardsThumbnail(media.image_name, media.extension),
        src: awardsOriginal(media.image_name, media.extension)
    }
    if (media.videoId) {
        media['video'] = {
            id: media.videoId,
            src: `https://www.youtube.com/embed/${media.videoId}`,
            thumbnail_video: awardsThumbnail(media.image_name, "mp4")
        };
    }
    delete media['videoId'];
    delete media['image_name'];
    return media;
})
